import { useEffect, useState } from "react";
import { ConsentDetails, UC_CMP } from "./types";

type ServiceName = string;

type CookieConsent = {
  services?: {
    [name: ServiceName]: boolean;
  };
  showDetailedLayer?: UC_CMP["showSecondLayer"];
};

const transformCookieConsentDetails = (
  consentDetails: ConsentDetails,
): CookieConsent["services"] =>
  Object.values(consentDetails.services).reduce<
    NonNullable<CookieConsent["services"]>
  >((acc, serviceData) => {
    if (serviceData.essential) {
      acc[serviceData.name] = true;
      return acc;
    }

    if (serviceData.consent === undefined) {
      return acc;
    }

    acc[serviceData.name] = serviceData.consent.given;
    return acc;
  }, {});

export const useCookieConsent = ({
  shouldRerenderOnConsentChange = false,
}: {
  shouldRerenderOnConsentChange?: boolean;
} = {}) => {
  const [cookieConsent, setCookieConsent] = useState<CookieConsent>({});

  /* istanbul ignore next: It is not worth it to create another test suite only for this @jest-environment  */
  if (typeof window === "undefined") return cookieConsent;

  const ucCmp = window?.__ucCmp;

  useEffect(() => {
    if (!ucCmp) {
      return;
    }

    const updateCookieConsent = async () => {
      const consentDetails = await ucCmp.getConsentDetails();
      setCookieConsent({
        services: transformCookieConsentDetails(consentDetails),
        showDetailedLayer: () => ucCmp.showSecondLayer(),
      });
    };
    updateCookieConsent();

    if (shouldRerenderOnConsentChange) {
      const listener = () => {
        updateCookieConsent();
      };

      window.addEventListener("UC_CONSENT", listener);

      return () => {
        window.removeEventListener("UC_CONSENT", listener);
      };
    }
  }, [ucCmp]);

  return cookieConsent;
};
