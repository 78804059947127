import { graphql, useStaticQuery } from "gatsby";
import React, { useContext } from "react";
import { InView } from "react-intersection-observer";
import styled from "styled-components";

import { useCookieConsent } from "@finanzchef24gmbh/cookie-consent/src/hook";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { Maybe, SocialIconsQuery } from "../../../types/graphql-types";
import { Theme } from "../../createHomePageTheme";
import Center from "../Center";
import { GlobalStateContext } from "../GlobalStateContextProvider";
import Markdown from "../Markdown";
import PhoneNumber from "./PhoneNumber";
import footerData from "./footer.json";
import Breadcrumbs from "./Breadcrumbs";

export const StyledFooter = styled.footer`
  background-color: ${(props) => props.theme.palette.brand.dark};
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.palette.white};
  text-decoration: none;

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding: 12px 0;
    margin-top: 0;
  }
`;

const FooterLayout = styled.div<{ breakOn: keyof Theme["layout"] }>`
  display: flex;
  flex-direction: column;
  > * + * {
    margin: ${(props) => props.theme.spacings.gigantic} 0 0;
  }
  @media (min-width: ${(props) => props.theme.layout[props.breakOn]}) {
    flex-direction: row;
    > * + * {
      margin: 0 0 0 ${(props) => props.theme.spacings.huge};
    }

    > * {
      flex-basis: 50%;
    }
  }
`;

const StyledCopyright = styled.div`
  align-self: center;
`;

const StyledBlockPhoneContact = styled.div`
  padding: ${(props) => props.theme.spacings.huge} 0;
  background-image: linear-gradient(
    180deg,
    ${(props) => props.theme.palette.brand.main},
    ${(props) => props.theme.palette.brand[600]}
  );
`;

const StyledPhoneContactLayout = styled(Spacings.Stack)`
  align-items: flex-start;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    align-items: center;
    text-align: center;
  }
`;

const Footer: React.FC = () => {
  const data = useStaticQuery<Maybe<SocialIconsQuery>>(graphql`
    query SocialIcons {
      linkedin: contentfulAsset(
        contentful_id: { eq: "7epY4eWElgsGS7vSys6ltu" }
      ) {
        file {
          url
        }
      }
      facebook: contentfulAsset(
        contentful_id: { eq: "2hx6Hs5vEfA9LNkXO2lxPV" }
      ) {
        file {
          url
        }
      }
      youtube: contentfulAsset(
        contentful_id: { eq: "2qRmXnrIpO73BhcMJCzj4T" }
      ) {
        file {
          url
        }
      }
      instagram: contentfulAsset(
        contentful_id: { eq: "4m8PwII6E9AEUf3fyjzvyR" }
      ) {
        file {
          url
        }
      }
    }
  `);

  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const cookieConsent = useCookieConsent();

  return (
    <InView
      as={StyledFooter}
      onChange={(inView) => {
        setGlobalState({
          ...globalState,
          hideSkyscraperAd: inView,
        });
      }}
    >
      <StyledBlockPhoneContact>
        <Center>
          <Spacings.Inset scale="big">
            <StyledPhoneContactLayout scale="big">
              <Text textStyle="headline4" as="h2" isOnDarkBackground>
                Sie haben noch Fragen?
              </Text>
              <Markdown priority="secondary" isOnDarkBackground>
                Bei Beratungsbedarf stehen Ihnen unsere Experten gerne zur
                Verfügung: via E‑Mail oder per Telefon unter der kostenlosen
                Telefonnummer.
              </Markdown>
              <PhoneNumber isOnDarkBackground alignItems="center" />
            </StyledPhoneContactLayout>
          </Spacings.Inset>
        </Center>
      </StyledBlockPhoneContact>

      <Breadcrumbs />

      <Center>
        <Spacings.Inset scale="big">
          <Spacings.Stack scale="gigantic">
            <FooterLayout breakOn="tabletBreakpoint">
              <FooterLayout breakOn="mobileBreakpoint">
                <Spacings.Stack scale="big">
                  <Text textStyle="headline6" isOnDarkBackground as="span">
                    Kontakt
                  </Text>
                  <Text textStyle="caption" isOnDarkBackground>
                    Finanzchef24 GmbH
                    <br />
                    Hohenlindener Str. 1<br />
                    81677 München
                  </Text>
                  <Text textStyle="caption" isOnDarkBackground>
                    info@finanzchef24.de
                  </Text>
                  <Text textStyle="caption" isOnDarkBackground>
                    Kostenlose Expertenberatung:
                    <br />
                    089 716 772 999
                  </Text>
                  <Text textStyle="headline6" isOnDarkBackground as="span">
                    Social Media
                  </Text>
                  <Spacings.Stack scale="small">
                    <StyledLink
                      href="https://www.facebook.com/Finanzchef24/"
                      rel="nofollow noopener"
                      target="_blank"
                    >
                      <Spacings.Inline alignItems="center">
                        {data?.facebook?.file?.url && (
                          <img
                            src={data.facebook.file.url}
                            width={19}
                            height={20}
                            alt="Finanzchef24 Facebook"
                          />
                        )}
                        <Text textStyle="caption" isOnDarkBackground>
                          Facebook
                        </Text>
                      </Spacings.Inline>
                    </StyledLink>

                    <StyledLink
                      href="https://www.youtube.com/channel/UCD_vZkZ9q4xhLBFLx6F_bRQ"
                      rel="nofollow noopener"
                      target="_blank"
                    >
                      <Spacings.Inline alignItems="center">
                        {data?.youtube?.file?.url && (
                          <img
                            src={data.youtube.file.url}
                            width={19}
                            height={15}
                            alt="Finanzchef24 Youtube"
                          />
                        )}
                        <Text textStyle="caption" isOnDarkBackground>
                          Youtube
                        </Text>
                      </Spacings.Inline>
                    </StyledLink>

                    <StyledLink
                      href="https://www.linkedin.com/company/finanzchef24/"
                      rel="nofollow noopener"
                      target="_blank"
                    >
                      <Spacings.Inline alignItems="center">
                        {data?.linkedin?.file?.url && (
                          <img
                            src={data.linkedin.file.url}
                            width={19}
                            height={18}
                            alt="Finanzchef24 LinkedIn"
                          />
                        )}
                        <Text textStyle="caption" isOnDarkBackground>
                          LinkedIn
                        </Text>
                      </Spacings.Inline>
                    </StyledLink>

                    <StyledLink
                      href="https://www.instagram.com/finanzchef24/"
                      rel="nofollow noopener"
                      target="_blank"
                    >
                      <Spacings.Inline alignItems="center">
                        {data?.instagram?.file?.url && (
                          <img
                            src={data.instagram.file.url}
                            width={19}
                            height={21}
                            alt="Finanzchef24 Instagram"
                          />
                        )}
                        <Text textStyle="caption" isOnDarkBackground>
                          Instagram
                        </Text>
                      </Spacings.Inline>
                    </StyledLink>
                  </Spacings.Stack>
                </Spacings.Stack>

                <Spacings.Stack scale="huge">
                  <Spacings.Stack scale="big">
                    <Text textStyle="headline6" isOnDarkBackground as="span">
                      Top-Produkte
                    </Text>
                    <Spacings.Stack scale="medium">
                      {footerData.topProducts.map((product) => (
                        <StyledLink
                          key={product.id}
                          href={`${process.env.GATSBY_ORIGIN}${product.path}`}
                        >
                          <Text textStyle="caption" isOnDarkBackground>
                            {product.text}
                          </Text>
                        </StyledLink>
                      ))}
                    </Spacings.Stack>
                  </Spacings.Stack>
                </Spacings.Stack>
              </FooterLayout>
              <FooterLayout breakOn="mobileBreakpoint">
                <Spacings.Stack scale="big">
                  <StyledLink href={`${process.env.GATSBY_ORIGIN}/ueber-uns`}>
                    <Text textStyle="headline6" isOnDarkBackground as="span">
                      Über uns
                    </Text>
                  </StyledLink>

                  <Spacings.Stack scale="medium">
                    <StyledLink
                      href={`${process.env.GATSBY_ORIGIN}/ueber-uns/unternehmen/auszeichnungen-und-preise`}
                    >
                      <Text textStyle="caption" isOnDarkBackground>
                        Auszeichnungen und Preise
                      </Text>
                    </StyledLink>

                    <StyledLink
                      href={`${process.env.GATSBY_ORIGIN}/ueber-uns/partner`}
                    >
                      <Text textStyle="caption" isOnDarkBackground>
                        Versicherungspartner
                      </Text>
                    </StyledLink>

                    <StyledLink
                      href={`${process.env.GATSBY_ORIGIN}/ueber-uns/kooperationspartner`}
                    >
                      <Text textStyle="caption" isOnDarkBackground>
                        Kooperationspartner
                      </Text>
                    </StyledLink>

                    <StyledLink
                      href={`${process.env.GATSBY_ORIGIN}/ueber-uns/jobs`}
                    >
                      <Text textStyle="caption" isOnDarkBackground>
                        Jobs
                      </Text>
                    </StyledLink>

                    <StyledLink
                      href={`${process.env.GATSBY_ORIGIN}/ueber-uns/team`}
                    >
                      <Text textStyle="caption" isOnDarkBackground>
                        Team
                      </Text>
                    </StyledLink>

                    <StyledLink href={`${process.env.GATSBY_ORIGIN}/presse`}>
                      <Text textStyle="caption" isOnDarkBackground>
                        Presse
                      </Text>
                    </StyledLink>
                    <StyledLink
                      href={`${process.env.GATSBY_ORIGIN}/ueber-uns/datenschutz`}
                    >
                      <Text textStyle="caption" isOnDarkBackground>
                        Datenschutz
                      </Text>
                    </StyledLink>
                    <StyledLink
                      href="#"
                      onClick={() => cookieConsent.showDetailedLayer?.()}
                    >
                      <Text textStyle="caption" isOnDarkBackground>
                        Cookie-Einstellungen
                      </Text>
                    </StyledLink>
                    <StyledLink
                      href={`${process.env.GATSBY_ORIGIN}/ueber-uns/agb`}
                    >
                      <Text textStyle="caption" isOnDarkBackground>
                        AGB
                      </Text>
                    </StyledLink>
                    <StyledLink
                      href={`${process.env.GATSBY_ORIGIN}/ueber-uns/impressum`}
                    >
                      <Text textStyle="caption" isOnDarkBackground>
                        Impressum / Erstinformation
                      </Text>
                    </StyledLink>
                  </Spacings.Stack>
                </Spacings.Stack>
                <Spacings.Stack scale="big">
                  <Text textStyle="headline6" isOnDarkBackground as="span">
                    Services
                  </Text>
                  <Spacings.Stack scale="medium">
                    {footerData.services.map((service) => (
                      <StyledLink
                        href={`${process.env.GATSBY_ORIGIN}${service.path}`}
                      >
                        <Text textStyle="caption" isOnDarkBackground>
                          {service.text}
                        </Text>
                      </StyledLink>
                    ))}
                    <StyledLink
                      href={`${process.env.GATSBY_CUSTOMER_ACCOUNT_URL}`}
                    >
                      <Text textStyle="caption" isOnDarkBackground>
                        Kundenkonto
                      </Text>
                    </StyledLink>
                  </Spacings.Stack>
                </Spacings.Stack>
              </FooterLayout>
            </FooterLayout>
            <StyledCopyright>
              <Text textStyle="caption" isOnDarkBackground>
                Finanzchef24 GmbH © {new Date().getFullYear()}
              </Text>
            </StyledCopyright>
          </Spacings.Stack>
        </Spacings.Inset>
      </Center>
    </InView>
  );
};

export default Footer;
