import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { createGlobalStyle, css, ThemeProvider } from "styled-components";

import designSystemTheme from "@finanzchef24gmbh/design-system/src/designTokens";
import getGlobalDesignSystemStyles from "@finanzchef24gmbh/design-system/src/globalStyles";
import { createHomepageTheme } from "../createHomePageTheme";
import InterUiRegularWoff from "../fonts/Inter-Regular.woff";
import InterUiRegularWoff2 from "../fonts/Inter-Regular.woff2";
import InterUiSemiBoldWoff from "../fonts/Inter-SemiBold.woff";
import InterUiSemiBoldWoff2 from "../fonts/Inter-SemiBold.woff2";
import { isTouchDevice } from "../utils/touchDevice";

const GlobalStyles = createGlobalStyle`
  ${getGlobalDesignSystemStyles()}

  @font-face {
    font-family: 'Inter UI';
    src:  url('${InterUiSemiBoldWoff2}') format('woff2'),
          url('${InterUiSemiBoldWoff}') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: optional;
  }
  @font-face {
    font-family: 'Inter UI';
    src:  url('${InterUiRegularWoff2}') format('woff2'),
          url('${InterUiRegularWoff}') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: optional;
  }

  ${(props) => {
    const backgroundGradient = `linear-gradient(${props.theme.palette.gray[50]} 600px, ${props.theme.palette.white} 800px);`;
    return css`
      body {
        font-family: "Inter UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-feature-settings: "tnum";
        min-height: 100vh;
        background: ${backgroundGradient};
        @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
          background: ${backgroundGradient};
          background-size: 100%, 100%;
          background-repeat: no-repeat;
          background-attachment: scroll, scroll, scroll;
        }
      }
    `;
  }}

  /*
  Overwrite the browsers defaults for these elements as some browsers e.g.
  Chrome set these elements to be system-ui which is more specific than the
  bodys font family.
  Also a CSS reset is not enough here, as it only sets the font to inherit
  which causes those elements to inherit the default browser styles again.
  */
  input, textarea, select, button {
    font-family: 'Inter UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  strong {
    font-weight: bold;
  }
`;

type LayoutProps = {
  containerWidth: number;
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children, containerWidth }) => {
  const homepageTheme = createHomepageTheme(designSystemTheme, containerWidth);

  useEffect(() => {
    if (!isTouchDevice()) {
      document.body.classList.add("device-can-hover");
    }
  }, []);

  return (
    <ThemeProvider theme={homepageTheme}>
      <GlobalStyles />
      <Helmet>
        <link
          rel="preload"
          href={InterUiRegularWoff2}
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          href={InterUiSemiBoldWoff2}
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
      </Helmet>
      {children}
    </ThemeProvider>
  );
};

export default Layout;
