import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from "react";

export type GlobalStateProps = {
  hideSkyscraperAd: boolean;
  hideStickyHeader: {
    onDesktop: boolean;
    onMobile: boolean;
  };
};

export type GlobalStateContextProps = {
  globalState: GlobalStateProps;
  setGlobalState: Dispatch<SetStateAction<GlobalStateProps>>;
};

export const GlobalStateContext = createContext({
  globalState: {} as GlobalStateProps,
  setGlobalState: (_: GlobalStateProps) => {},
});

const GlobalStateContextProvider: React.FC<{ children: React.ReactNode }> = (
  props,
) => {
  const initialState = {
    hideSkyscraperAd: false,
    hideStickyHeader: {
      onDesktop: false,
      onMobile: false,
    },
  };

  const [globalState, setGlobalState] =
    useState<GlobalStateProps>(initialState);

  const contextProviderValue = {
    globalState,
    setGlobalState,
  };

  return (
    <GlobalStateContext.Provider
      value={contextProviderValue as GlobalStateContextProps}
    >
      {props.children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateContextProvider;
