import { graphql, useStaticQuery } from "gatsby";
import Img, { FixedObject, FluidObject, GatsbyImageProps } from "gatsby-image";
import React from "react";
import Helmet from "react-helmet";
import styled, { css } from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text, {
  fontSizeByTextStyle,
  lineHeightByTextStyle,
} from "@finanzchef24gmbh/design-system/src/Text";
import { BlockHeroProps } from ".";
import { HeroImageQuery, Maybe } from "../../../../types/graphql-types";
import { Theme } from "../../../createHomePageTheme";
import Center from "../../Center";
import { HEADER_HEIGHT } from "../../Header";
import Responsive from "../../Responsive";
import CallToAction from "./CallToAction";
import constants from "./constants";
import SavingsBadge from "./SavingsBadge";
import SocialProof from "./SocialProof";
import TrustSealsSelfHosted from "./TrustSealsSelfHosted";
import { TOP_HEADER_HEIGHT } from "../../TopHeader";
import { usePageContext } from "../../../context/PageContext";

const StyledHero = styled.div<{
  paddingTopFactor: number;
  isTopBarVisible: boolean;
}>`
  position: relative;
  padding-top: ${(props) => props.theme.spacings.huge};
  background-color: ${(props) => props.theme.palette.brand.light};
  padding-bottom: ${(props) => props.theme.spacings.huge};
  margin-bottom: -${(props) => props.theme.spacings.huge};
  margin-top: calc(${TOP_HEADER_HEIGHT} + ${HEADER_HEIGHT});

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding-top: calc(${(props) => props.theme.spacings.gigantic} * 2.5);
    background: none;
  }

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    padding-top: calc(
      ${(props) => props.theme.spacings.gigantic} *
        ${(props) => props.paddingTopFactor}
    );
  }

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    margin-top: ${(props) => (props.isTopBarVisible ? TOP_HEADER_HEIGHT : 0)};
    padding-top: ${(props) =>
      props.isTopBarVisible ? TOP_HEADER_HEIGHT : "4.5rem"};
  }
`;

const BackgroundGradient = styled.div`
  position: absolute;
  /* A negative top to overlap the gradient over the header. The header height is 64px. */
  top: -${HEADER_HEIGHT};
  left: 0;
  right: 0;
  /* makes the gradient start even below the hero */
  bottom: -250px;
  transform-origin: 0;
  background: linear-gradient(
    to bottom,
    ${(props) => props.theme.palette.brand[400]} 0%,
    ${(props) => props.theme.palette.brand[500]} 50%,
    ${(props) => props.theme.palette.brand[600]} 100%
  );
  overflow: hidden;

  /* the goal of the rotation is that the gradient background crosses the
    carbine */
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    transform: skewY(-35deg);
  }

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    transform: skewY(-28deg);
  }

  @media (min-width: 90em) {
    transform: skewY(-18deg);
  }
`;

const skewedImgStyles = css`
  transform-origin: 0;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    transform: skewY(35deg);
  }

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    transform: skewY(28deg);
  }

  @media (min-width: 90em) {
    transform: skewY(18deg);
  }
`;

const hasHeroImageStyles = (props: { theme: Theme; hasHeroImage: boolean }) =>
  props.hasHeroImage &&
  css`
    display: none !important;

    @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
      display: block !important;
    }
  `;

const SkewedImg = styled(Img)<
  GatsbyImageProps & {
    hasHeroImage: boolean;
  }
>`
  height: 100%;
  filter: grayscale(100%);
  opacity: 0.2;
  ${skewedImgStyles};
  ${hasHeroImageStyles};
`;

const SkewedSvgObject = styled.object<{ hasHeroImage: boolean }>`
  ${skewedImgStyles};
  ${hasHeroImageStyles};
`;

const CarbineImage = styled.div`
  position: absolute;
  /* A negative top to overlap the gradient over the header. The header height is 64px. */
  top: -${HEADER_HEIGHT};
  right: 0;
  width: 60%;
  margin-left: auto;
  z-index: 0;
  display: none;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    width: 50%;
    display: block;
  }

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    width: 42%;
  }
`;

const MAX_CARD_HEIGHT = "17em";

const HeroContent = styled(Center)<{ fixedHeight: boolean }>`
  /* create a new stacking context for the content so that it's above the
  carbine image */
  position: relative;

  display: -ms-grid;
  display: grid;
  -ms-grid-rows: ${(props) =>
    `auto ${props.theme.spacings.huge} auto ${props.theme.spacings.big} auto`};
  grid-template-rows: ${(props) =>
    `auto ${props.theme.spacings.huge} auto ${props.theme.spacings.big} auto`};
  -ms-grid-columns: auto auto auto auto;
  grid-template-columns: auto auto auto auto;

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    -ms-grid-rows: ${(props) => `
      auto
      ${props.theme.spacings.huge}
      ${props.fixedHeight ? MAX_CARD_HEIGHT : "auto"}
      ${props.theme.spacings.big}
      auto
    `};
    grid-template-rows: ${(props) => `
      auto
      ${props.theme.spacings.huge}
      ${props.fixedHeight ? MAX_CARD_HEIGHT : "auto"}
      ${props.theme.spacings.big}
      auto
    `};
    -ms-grid-columns: calc(${constants.BLOCK_HERO.ctaCard.width} - 100px) 100px
      400px 1fr;
    grid-template-columns: calc(${constants.BLOCK_HERO.ctaCard.width} - 100px) 100px 400px 1fr;
  }
`;

const Title = styled(Spacings.Stack)<{ limitWidth?: boolean }>`
  -ms-grid-row: 1;
  grid-row: 1 / 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-column: 1 / 5;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
  }

  ${(props) =>
    !props.limitWidth &&
    css`
      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        -ms-grid-column: 1;
        -ms-grid-column-span: 4;
        grid-column: 1 / 5;
      }
    `}
`;

const StyledTitleFirstLine = styled(Text)`
  font-weight: ${(props) => props.theme.typography.weights.regular};

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    font-size: ${(props) => fontSizeByTextStyle(props.theme).headline5}rem;
    line-height: ${(props) => lineHeightByTextStyle(props.theme).headline5};
  }
`;

const StyledTitleSecondLine = styled(Text)<{ limitWidth: boolean }>`
  font-weight: ${(props) => props.theme.typography.weights.semiBold};

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    font-size: ${(props) => fontSizeByTextStyle(props.theme).headline3}rem;
    line-height: ${(props) => lineHeightByTextStyle(props.theme).headline3};
  }

  ${(props) =>
    props.limitWidth &&
    css`
      @media (max-width: ${props.theme.layout.tabletBreakpoint}) {
        width: 50%;
      }
    `}
`;

const TrapezoidImage: React.ComponentType<GatsbyImageProps> = styled(Img)`
  -ms-grid-row: 1;
  -ms-grid-row-span: 5;
  grid-row: 1 / 6;
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  grid-column: 2 / 4;
  margin: auto 0;
  transform: perspective(500px) rotateY(-10deg) scale(0.9);
  border-radius: ${(props) => props.theme.borders.radius.medium}px;
  display: none;

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    display: block;
  }
`;

const StyledHeroImageWrapper = styled.div`
  position: absolute;
  bottom: 56px;
  right: 0;
  display: none;

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    display: flex;
  }
`;

const StyledHeroImageSvgObject = styled.object`
  height: 334px;
`;

const TrustSealsContainer = styled.div<{ isSocialProofVisible: boolean }>`
  position: relative;
  -ms-grid-row: 5;
  grid-row: 5;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-column: 1 / 5;
  display: flex;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    justify-content: flex-end;
  }

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    ${(props) =>
      props.isSocialProofVisible &&
      css`
        -ms-grid-row: 3;
        grid-row: 3;
        align-items: flex-end;
      `};
  }
`;

const CallToActionContainer = styled.div`
  position: relative;
  z-index: 1;
  -ms-grid-row: 3;
  grid-row: 3 / 4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-column: 1 / 5;
  margin: auto 0;
`;

const StyledSavingsResponsive = styled(Responsive)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    max-width: ${constants.BLOCK_HERO.ctaCard.width};
  }
`;

type OldHeroConceptProps = {
  contentBlock: BlockHeroProps["contentBlock"];
};

// TODO: delete this file once all Block Hero entries in Contentful have been updated
const OldHeroConcept: React.FC<OldHeroConceptProps> = (props) => {
  const { isTopBarVisible } = usePageContext();

  const data = useStaticQuery<Maybe<HeroImageQuery>>(graphql`
    query HeroImage {
      contentfulAsset(contentful_id: { eq: "4qH0rsjx4cKDLRhY1y5YV9" }) {
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
          src
        }
      }
    }
  `);

  return (
    <StyledHero
      isTopBarVisible={Boolean(isTopBarVisible)}
      paddingTopFactor={
        props.contentBlock.image || props.contentBlock.heroImage ? 1 : 2.5
      }
    >
      <BackgroundGradient>
        {props.contentBlock.backgroundImage?.fluid ? (
          <SkewedImg
            fluid={props.contentBlock.backgroundImage.fluid as FluidObject}
            loading="eager"
            fadeIn={false}
            imgStyle={{ objectPosition: "top" }}
            hasHeroImage={Boolean(props.contentBlock.heroImage)}
            alt=""
          />
        ) : (
          props.contentBlock.backgroundImage?.file?.url && (
            <SkewedSvgObject
              hasHeroImage={Boolean(props.contentBlock.heroImage)}
              type="image/svg+xml"
              data={props.contentBlock.backgroundImage.file.url}
            />
          )
        )}
      </BackgroundGradient>

      {props.contentBlock.showIndustrySelection &&
        !props.contentBlock.image &&
        !props.contentBlock.heroImage && (
          <CarbineImage>
            {data?.contentfulAsset?.fluid?.srcWebp && (
              <Helmet>
                <link
                  rel="preload"
                  as="image"
                  href={data.contentfulAsset.fluid.srcWebp}
                />
              </Helmet>
            )}

            {data?.contentfulAsset?.fluid ? (
              <Img
                fluid={data.contentfulAsset.fluid as FluidObject}
                alt=""
                loading="eager"
                fadeIn={false}
              />
            ) : null}
          </CarbineImage>
        )}

      <HeroContent fixedHeight={Boolean(props.contentBlock.image)}>
        <Title scale="huge" limitWidth={Boolean(props.contentBlock.image)}>
          <Spacings.Stack scale="medium">
            {props.contentBlock.titleFirstLine ? (
              <StyledTitleFirstLine
                textStyle="headline4"
                as="span"
                isOnDarkBackground
                dangerouslySetInnerHTML={{
                  __html: props.contentBlock.titleFirstLine,
                }}
              />
            ) : null}

            {props.contentBlock.titleSecondLine ? (
              <StyledTitleSecondLine
                textStyle="headline1"
                as="h1"
                isOnDarkBackground
                limitWidth={Boolean(
                  props.contentBlock.savingsBadgeWithModalOverlay,
                )}
                dangerouslySetInnerHTML={{
                  __html: props.contentBlock.titleSecondLine,
                }}
              />
            ) : null}
          </Spacings.Stack>

          {props.contentBlock.socialProofRichText?.json && (
            <SocialProof
              socialProofRichText={props.contentBlock.socialProofRichText}
            />
          )}
        </Title>

        {props.contentBlock.image?.fixed && (
          <TrapezoidImage
            style={{ display: "inherit" }}
            fixed={props.contentBlock.image.fixed as FixedObject}
            alt=""
            loading="eager"
            fadeIn={false}
          />
        )}

        <CallToActionContainer>
          <CallToAction
            {...props}
            hasSavingsBadge={Boolean(
              props.contentBlock.savingsBadgeWithModalOverlay,
            )}
          />

          {props.contentBlock.savingsBadgeWithModalOverlay && (
            <StyledSavingsResponsive hideOn={["desktop"]}>
              <SavingsBadge
                content={{
                  ...props.contentBlock.savingsBadgeWithModalOverlay,
                }}
              />
            </StyledSavingsResponsive>
          )}
        </CallToActionContainer>
        <TrustSealsContainer
          isSocialProofVisible={Boolean(
            props.contentBlock.socialProofRichText?.json,
          )}
        >
          {props.contentBlock.heroImage && (
            <StyledHeroImageWrapper>
              {props.contentBlock.heroImage?.fixed ? (
                <Img
                  fixed={props.contentBlock.heroImage.fixed as FixedObject}
                  alt=""
                  loading="eager"
                  fadeIn={false}
                />
              ) : (
                props.contentBlock.heroImage?.file?.url && (
                  <StyledHeroImageSvgObject
                    type="image/svg+xml"
                    data={props.contentBlock.heroImage.file.url}
                    width="360"
                  />
                )
              )}

              {props.contentBlock.savingsBadgeWithModalOverlay && (
                <SavingsBadge
                  content={{
                    ...props.contentBlock.savingsBadgeWithModalOverlay,
                  }}
                  isOldHeroConcept
                />
              )}
            </StyledHeroImageWrapper>
          )}

          <TrustSealsSelfHosted
            hideOnDesktop={props.contentBlock.hideTrustSeals || false}
          />
        </TrustSealsContainer>
      </HeroContent>
    </StyledHero>
  );
};

export default OldHeroConcept;
