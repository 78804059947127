import { graphql } from "gatsby";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import styled, { css } from "styled-components";

import { useCookieConsent } from "@finanzchef24gmbh/cookie-consent/src/hook";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import {
  BlockAd_ContentfulBlockAdFragment,
  ContentfulBasicLandingPage,
} from "../../../types/graphql-types";
import { BROWSER_DEFAULT_FONT_SIZE } from "../../createHomePageTheme";
import Center, { CENTER_SIDE_PADDING } from "../Center";
import { BLOCKS_TOP_MARGIN } from "../ContentBlocks";
import { GlobalStateContext } from "../GlobalStateContextProvider";

type BlockAdProps = {
  contentBlock: BlockAd_ContentfulBlockAdFragment;
  pageId?: ContentfulBasicLandingPage["contentful_id"];
};

const StyledDesktopCenter = styled(Center)<{
  showOn?: number;
}>`
  display: none;

  ${(props) =>
    props.showOn &&
    css`
      /* add side padding from Center to avoid horizontal scrollbar */
      @media (min-width: ${props.showOn +
        parseInt(props.theme.spacings[CENTER_SIDE_PADDING], 10) *
          2 *
          BROWSER_DEFAULT_FONT_SIZE}px) {
        display: block;
      }
    `}
`;

const StyledMobileCenter = styled(Center)<{
  hideOn?: number;
}>`
  ${(props) =>
    props.hideOn
      ? css`
          /* add side padding from Center to avoid horizontal scrollbar */
          @media (min-width: ${props.hideOn +
            parseInt(props.theme.spacings[CENTER_SIDE_PADDING], 10) *
              2 *
              BROWSER_DEFAULT_FONT_SIZE}px) {
            display: none;
          }
        `
      : css`
          @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
            display: none;
          }
        `}
`;

const StyledNotice = styled(Text)`
  font-size: 0.7rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.gray[400]};
`;

const StyledAd = styled.div<{
  adWidth: number;
  adHeight: number;
}>`
  width: ${(props) => props.adWidth}px;
  height: ${(props) => props.adHeight}px;
`;

const StyledSkyscraperAd = styled.div<{
  hide?: boolean;
  adHeight: number;
}>`
  display: none;
  position: sticky !important;
  top: ${(props) => props.theme.spacings.gigantic};
  z-index: 1;

  @media (min-width: 100rem) {
    display: block;
    margin-left: calc(
      50vw + ${(props) => parseInt(props.theme.layout.containerWidth, 10) / 2}em +
        ${(props) => props.theme.spacings.gigantic}
    );
    margin-bottom: calc(
      -${(props) => props.adHeight}px - ${(props) => props.theme.spacings[BLOCKS_TOP_MARGIN]}
    );
    opacity: ${(props) => (props.hide ? 0 : 1)};
    transition: opacity 0.2s ease;
  }
`;

const BlockAd: React.FC<BlockAdProps> = (props) => {
  const [googletagAd, setGoogletagAd] = useState((): ReactElement => <></>);
  const { globalState } = useContext(GlobalStateContext);
  const consentServiceName = "Google Publisher Tags";
  const { services } = useCookieConsent({
    shouldRerenderOnConsentChange: true,
  });

  useEffect(() => {
    const snippet = (
      path: string | null | undefined,
      width: number | null | undefined,
      height: number | null | undefined,
      id: string | null | undefined,
    ) => `
      googletag = window.googletag || {cmd: []};

      googletag.cmd.push(function() {
        googletag
          .defineSlot('${path}', [${width}, ${height}], '${id}')
          .addService(googletag.pubads())
          .setTargeting('contentful_id', '${props.pageId}');

        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
      });

      googletag.cmd.push(function() {
        googletag.display('${id}');
      });
    `;

    const responsiveAd = (
      <>
        {props.contentBlock.desktopAdPath &&
          props.contentBlock.desktopAdId &&
          props.contentBlock.desktopAdWidth &&
          props.contentBlock.desktopAdHeight && (
            <StyledDesktopCenter
              showOn={Number(props.contentBlock.desktopAdWidth)}
              intrinsic
            >
              <Spacings.Stack alignItems="center">
                {props.contentBlock.notice && (
                  <StyledNotice>{props.contentBlock.notice}</StyledNotice>
                )}

                <StyledAd
                  id={props.contentBlock.desktopAdId}
                  adWidth={props.contentBlock.desktopAdWidth}
                  adHeight={props.contentBlock.desktopAdHeight}
                >
                  <Helmet>
                    <script>
                      {snippet(
                        props.contentBlock.desktopAdPath,
                        props.contentBlock.desktopAdWidth,
                        props.contentBlock.desktopAdHeight,
                        props.contentBlock.desktopAdId,
                      )}
                    </script>
                  </Helmet>
                </StyledAd>
              </Spacings.Stack>
            </StyledDesktopCenter>
          )}

        {props.contentBlock.mobileAdPath &&
          props.contentBlock.mobileAdId &&
          props.contentBlock.mobileAdWidth &&
          props.contentBlock.mobileAdHeight && (
            <StyledMobileCenter
              hideOn={Number(props.contentBlock.desktopAdWidth)}
              intrinsic
            >
              <Spacings.Stack alignItems="center">
                {props.contentBlock.notice && (
                  <StyledNotice>{props.contentBlock.notice}</StyledNotice>
                )}

                <StyledAd
                  id={props.contentBlock.mobileAdId}
                  adWidth={props.contentBlock.mobileAdWidth}
                  adHeight={props.contentBlock.mobileAdHeight}
                >
                  <Helmet>
                    <script>
                      {snippet(
                        props.contentBlock.mobileAdPath,
                        props.contentBlock.mobileAdWidth,
                        props.contentBlock.mobileAdHeight,
                        props.contentBlock.mobileAdId,
                      )}
                    </script>
                  </Helmet>
                </StyledAd>
              </Spacings.Stack>
            </StyledMobileCenter>
          )}
      </>
    );

    setGoogletagAd(responsiveAd);
  }, []);

  if (!services?.[consentServiceName]) {
    return null;
  }

  return (
    <>
      <Helmet>
        <script
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
          type="text/plain"
          data-usercentrics={consentServiceName}
        />
      </Helmet>

      {props.contentBlock.desktopAdPath &&
      props.contentBlock.desktopAdId &&
      props.contentBlock.desktopAdWidth &&
      props.contentBlock.desktopAdHeight &&
      props.contentBlock.adType === "skyscraper" ? (
        <StyledSkyscraperAd
          adHeight={props.contentBlock.desktopAdHeight}
          hide={globalState.hideSkyscraperAd}
        >
          {googletagAd}
        </StyledSkyscraperAd>
      ) : (
        googletagAd
      )}
    </>
  );
};

export const blockAdFragment = graphql`
  fragment BlockAd_ContentfulBlockAd on ContentfulBlockAd {
    adType
    notice
    desktopAdPath
    desktopAdWidth
    desktopAdHeight
    desktopAdId
    mobileAdPath
    mobileAdWidth
    mobileAdHeight
    mobileAdId
  }
`;

export default BlockAd;
