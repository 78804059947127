export const getCookiesMap = () =>
  document.cookie.split(";").reduce(
    (cookieObject, cookieString) => {
      const cookieNameAndValue = cookieString
        .split("=")
        .map((cookiePart) => cookiePart.trim());

      try {
        cookieObject[cookieNameAndValue[0]] = JSON.parse(cookieNameAndValue[1]);
      } catch (error) {
        cookieObject[cookieNameAndValue[0]] = cookieNameAndValue[1];
      }

      return cookieObject;
    },
    {} as Record<string, any>,
  );
